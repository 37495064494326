<template>
  <div class="achievement--expanded--each__container">
    <div class="achievement--expanded--each py-10 border-b-2">
      <div class="wrapper flex flex-wrap">
        <div class="date md:w-1/5 pt-1 text-gray-600 font-medium">
          <h1 class="text-lg">{{ getDate }}</h1>
        </div>
        <div class="content md:w-4/5 md:pl-8 md:pt-0 pt-8">
          <h1
            v-html="getTitle"
            class="md:text-3xl text-2xl title font-bold"
          ></h1>
          <p v-html="getDesc" class="mt-8 md:text-lg text-gray-700"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AchievemntXEach",
  data() {
    return {
      options: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    };
  },
  props: {
    achievement: {
      type: Object,
      require: true,
    },
  },
  computed: {
    getDate() {
      return this.achievement.date.toLocaleDateString("en-US", this.options);
    },
    getTitle() {
      return this.achievement.title;
    },
    getDesc() {
      return this.achievement.desc;
    },
  },
};
</script>

<style lang="scss" scoped>
.achievement--expanded--each__container {
  .achievement--expanded--each {
    .wrapper {
      .content {
        .title {
          ::v-deep b {
            color: $teal;
            background: $lightcyan;
            @apply px-1;
            @apply rounded-md;
          }
        }
      }
    }
  }
}
</style>
