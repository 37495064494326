const achievements = [
  {
    date: "26/05/2020",
    title: "Trending developer on GitHub",
    desc: "On 26 May 2020, I was the trending developer on GitHub. On the same day ytmdl was also trending.",
  },
  {
    date: "30/03/2021",
    title: "Ytmdl Web has <b>4.2k+</b> monthly active users",
    desc: "Since the release one month earlier, Ytmdl Web has sustained 4.2k active users for the month",
  },
  {
    date: "28/02/2021",
    title:
      "Ytmdl Web V2's API got <b>95k+</b> requests and <b>35k+</b> page visits within three days of release",
    desc: "Ytmdl Web V2 was shared on Reddit and in a bit of time it went to the FrontPage of Reddit as well as HackerNews",
  },
  {
    date: "28/02/2021",
    title: "Ytmdl Web V2 App becomes <b>trending</b> repo on GitHub",
    desc: "<a href='https://github.com/deepjyoti30/ytmdl-web-v2'>ytmdl-web-v2</a> became the #1 trending repo for VueJS on GitHub and #4 trending repo overall",
  },
  {
    date: "11/02/2021",
    title: "API's recieved <b>84k+</b> hits in the last 4 monhts",
    desc: "Ytmdl's, blog's and Repostatus' API's recieved 84081 hits in total in the last 4 months",
  },
  {
    date: "23/11/2020",
    title: "Ytmdl recieved <b>1k+</b> stars on GitHub",
    desc: "Ytmdl, which is a music extraction tool that let's users add metadata as well, got 1k stars on GitHub",
  },
  {
    date: "7/01/2021",
    title: "Ytmdl is downloaded <b>75k+</b> times from PyPI",
    desc: "Ytmdl is downloaded 75256 times from the <a href='https://pepy.tech/project/ytmdl'>Python Package Index</a>",
  },
  {
    date: "26/05/2020",
    title: "Ytmdl becomes a <b>trending</b> repo on GitHub",
    desc: "On this day, Ytmdl's repo was visited so many times by users that it became trending on GitHub",
  },
  {
    date: "3/10/2020",
    title: "Ytmdl Web got <b>70k+</b> hits within a month of launch",
    desc: "Web version of Ytmdl. Backend got 50k+ hits within a month and 30k+ users over 3 months",
  },
  {
    date: "3/12/2020",
    title: "Repostatus got <b>13k+</b> hits within a month of launch.",
    desc: "It is a tool used to find the happiness status of a GitHub repository",
  },
  {
    date: "11/12/2020",
    title: "downloader-cli got <b>10.8k+</b> downloads from PyPI",
    desc: "<a href='https://github.com/deepjyoti30/downloader-cli'>downloader-cli</a>, a python tool/library that works as a download manager, was downloaded 10586 times from <a href='https://pepy.tech/project/downloader-cli'>Python Package Index</a>",
  },
  {
    date: "11/12/2020",
    title: "QuickWall got <b>12.2k+</b> downloads from PyPI",
    desc: "It is a python tool used to set wallpapers in Linux based systems along with themes, was downloaded 12256 times from <a href='https://pepy.tech/project/QuickWall'>Python Package Index</a>",
  },
];

export default achievements;
