<template>
  <div class="achievements__container">
    <achievement-content />
    <Footer />
  </div>
</template>

<script>
import AchievementContent from "../components/AchievementContent.vue";
import Footer from "../components/Footer.vue";
export default {
  components: { AchievementContent, Footer },
  name: "Achievements",
};
</script>
