<template>
  <div class="achievement--content__container">
    <div
      class="
        achievement--content
        md:py-32
        py-24
        2xl:w-2/5
        xl:w-3/5
        md:w-4/5
        w-10/12
        mr-auto
        ml-auto
      "
    >
      <div class="top--head text-center">
        <generic-header :content="getHeaderContent" />
      </div>
      <div class="achievements--content md:mt-40 mt-24">
        <achievement-x-each
          v-for="(value, id) in getAchievements"
          :key="id"
          :achievement="value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AchievementXEach from "./AchievementXEach.vue";
import GenericHeader from "./GenericHeader.vue";
import achievements from "@/static/achievements.js";

export default {
  components: { GenericHeader, AchievementXEach },
  name: "AchievementContent",
  methods: {
    getIsoDateFromString: function (dateStr) {
      /**
       * Return a JS parsable date string from the passed string
       *
       * The incoming string will be of format
       * DD/MM/YY.
       *
       * We first need to convert it to YYYY-MM-DD and then pass it to
       * the Date constructor which will return a proper ISO Date
       */
      return new Date(dateStr.split("/").reverse().join("-"));
    },
    sortAchievementsOnDate: function (achievementData) {
      /**
       * Sort the passed list based on the date it was achieved.
       */
      // Replace all the date with their ISO strings
      if (typeof achievementData[0].date === "string") {
        achievementData.forEach(
          (el) => (el.date = this.getIsoDateFromString(el.date))
        );
      }
      return achievementData
        .sort((firstEl, secondEl) => {
          return firstEl.date > secondEl.date ? 1 : -1;
        })
        .reverse();
    },
  },
  computed: {
    getHeaderContent() {
      return {
        heading: "Work speaks. Achievemnts are proof.",
        text: "Over the years, I have had many achievements. From becoming the trending developer on GitHub to getting more than 100k users in 3 days within launch.",
      };
    },
    getAchievements() {
      return this.sortAchievementsOnDate(achievements);
    },
  },
};
</script>
